
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { EventUserStatistics, getMe, getUser, getUserWteStatistics, PrizeStatus } from '@/services/api';
import { formatLocalTime } from '@/utils/format-time';
import { PartialUser } from '@/interfaces/User';
import { setVerifiedStatus, setVerifiedType } from './utils';
import SectionLayout from '@/components/SectionLayout.vue';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialUser & {} = {
  id: null,
  memberNo: null,
  username: null,
  password: null,
  contactMail: '',
  birthday: null,
  createdAt: null,
  walletAddress: null,
  isNFTMember: null,
  isBlack: null,
  subscription: null,
  review: null,
  registerMethod: null,
  oauthProvider: null,
  oauthEmail: null,
  lastLoginTime: null,
  lastLoginIp: null,
  ipNation: null,
  language: null,
  comment: null,
  idPhoto: null
};

const USER_WTE_STATUS_TRANSLATE = {
  paid: '已提領',
  processing: '系統處理中',
  invalid: '未符合資格',
  valid: '尚未提領'
};

export default defineComponent({
  components: {
    SectionLayout
  },
  setup() {
    const userId = useRoute().params.id as string;
    const data = ref(DEFAULT_FORM_VALUES);
    const userWteStatistics = ref<EventUserStatistics[]>([]);

    const fetchUser = async() => {
      try {
        const res = await getUser({ userId });
        data.value = res.data;
      } catch (error) {
        console.log('fetchUser error', error);
      }
    };

    const fetchUserWteStatistics = async() => {
      try {
        const { data } = await getUserWteStatistics(+userId);
        userWteStatistics.value = data;
        console.log('fetchUserWteStatistics data', data);
      } catch (error) {
        console.log('fetchUserWteStatistics error', error);
      }
    };

    const getEventRange = ({ eventStartedAt, eventEndedAt }: { eventStartedAt?: string, eventEndedAt?: string }) => {
      if (!eventStartedAt || !eventEndedAt) return '-';
      return `${dayjs(eventStartedAt).format('YYYY-MM-DD')} ~ ${dayjs(eventEndedAt).format('YYYY-MM-DD')}`;
    };

    const formatSubscription = (data: PartialUser) => {
      const { subscription } = data;
      const startedAt = subscription?.startedAt;
      const endedAt = subscription?.endedAt;
      const planName = subscription?.plan?.name;
      const planId = subscription?.plan?.id;
      const isManual = subscription?.isManual;
      let result = '-';
      if (subscription) {
        const planDescription = (planId > 0) ? planName : '指定區間';
        result = (isManual) ? `人工方案-${planDescription}` : planDescription;
      }
      return result;
    };

    const formatValidateDes = (data: PartialUser) => {
      const { review } = data;
      const isManual = review?.isManual;
      let result = '-';
      if (!isEmpty(data?.review?.verifiedType)) {
        const validateName = setVerifiedType(data?.review?.verifiedType);
        result = (isManual) ? `人工驗證-${validateName}` : validateName;
      }
      return result;
    };

    onMounted(() => {
      fetchUser();
      fetchUserWteStatistics();
    });

    return {
      USER_WTE_STATUS_TRANSLATE,
      data,
      userWteStatistics,
      getEventRange,
      setVerifiedType,
      formatLocalTime,
      setVerifiedStatus,
      formatSubscription,
      formatValidateDes
    };
  }
});
